html,
body {
	height: 100%;
}

#root {
	min-height: 100vh;
}

.App {
	background-color: #f5f5f5;
	min-height: 100vh;
	color: #3c3c3c;
	font-family: noto-sans;
}

.rounded_lg {
	border-radius: 20px !important;
}

.section_name {
	border-bottom: 4px solid #ff0000;
}

.vert_divider {
	position: relative;
	width: 1px;
	background: rgb(206, 206, 206);
	border: none;
	margin: 0px;
}

.bg-gradient {
	background: #e2ad49;
	background: -moz-linear-gradient(
		268deg,
		rgb(233, 20, 20) 0,
		rgb(248, 181, 136) 100%
	);
	background: -webkit-linear-gradient(
		268deg,
		rgb(233, 20, 20) 0,
		rgb(248, 181, 136) 100%
	);
	background: linear-gradient(268deg, rgb(233, 20, 20), rgb(248, 181, 136));
	background-size: 400% 400%;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#47a06e", endColorstr="#3b7cb4", GradientType=1);
	-webkit-animation: gradient 15s ease infinite;
	-moz-animation: gradient 15s ease infinite;
	animation: gradient 15s ease infinite;
}

.zoom_on_hover {
	transition: transform 0.3s;
}

.zoom_on_hover:hover {
	transform: scale(1.02);
}
